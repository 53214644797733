.DetailDemo_Container{
     width: 100%; 
     padding: 3% 0;
}
.DetailDemo_MainDiv{
        width: 100%;
        display: flex;
        justify-content: space-between;
}
.DetailDemo_Typo{
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5%;
}
.DetailDemo_Title{
    font-size: 2rem !important; 
    font-weight: 800 !important;

}
.DetailDemo_Divider{
     height: 3px; 
     width: 10% !important; 
     border-radius: 4px !important;
}
.DetailDemo_Desp{
    width: 70% !important;
    font-weight: 400 !important;
    font-size: .9rem !important;
    margin: 4% 0 !important;
}
.DetailDemo_Btn{
    margin: 1% 0;
    padding: 3% 5%;
    font-size: 1rem;
    color: #f8f8f8;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: .5s;
}
.DetailDemo_Btn:hover{
    margin: 0 0 2% 0;

}
.DetailDemo_ImgContainer{
        width: 70%;
        display: flex;
        justify-content: flex-end;
        position: relative;
}
.DetailDemo_RoundDiv{
        width: 60%;
        height: 550px;
        border-radius: 275px 0 0 275px;
}
.DetailDemo_SquareDiv{
    width: 60%;
    height: 550px;
}

.DetailDemo_Img{
    position: absolute;
    width: 50%;
    left: 20%;
    top: 10%;
}

@media only screen and (max-width: 1700px) {
    .DetailDemo_RoundDiv{
        height: 450px;
        border-radius: 225px 0 0 225px;
}
.DetailDemo_SquareDiv{
    height: 450px;
}


}
@media only screen and (max-width: 1400px) {
    .DetailDemo_Desp{
        width: 90% !important;
        margin: 5% 0 !important;
    }
.DetailDemo_Img{
    width: 60%;
}

}
@media only screen and (max-width: 1200px) {
    .DetailDemo_RoundDiv{
        height: 400px;
        border-radius: 200px 0 0 200px;
}
.DetailDemo_SquareDiv{
    height: 400px;
}
.DetailDemo_Img{
    width: 60%;
    top:10%;
}
.DetailDemo_Divider{
    width: 15% !important; 
}

}
@media only screen and (max-width: 1024px) {
    .DetailDemo_RoundDiv{
        width: 70%;
        height: 350px;
        border-radius: 175px 0 0 175px;
}
.DetailDemo_SquareDiv{
    height: 350px;
}
.DetailDemo_Img{
    width: 65%;
    left: 8%;
}

.DetailDemo_Desp{
    width: 95% !important;
    font-size: 1rem !important;
}
.DetailDemo_Btn{
    font-size: 1rem;
   
}

}
@media only screen and (max-width: 900px) {
    .DetailDemo_RoundDiv{
        width: 70%;
        height: 300px;
        border-radius: 150px 0 0 150px;
}
.DetailDemo_SquareDiv{
    height: 300px;
}
.DetailDemo_Img{
    width: 70%;
    top:12%;
}
.DetailDemo_MainDiv{
    justify-content: space-between;
    flex-direction: column;
}
.DetailDemo_Typo{
    width: 100%;
    margin-left: 5%;
}
.DetailDemo_Desp{
    width: 75% !important;
    /* font-size: .8rem !important; */
    margin: 8% 0 !important;

}
.DetailDemo_Btn{
    padding:  3% 4%;
   
}
.DetailDemo_ImgContainer{
    margin-top: 5%;
    width: 100%;
    height: 500px;
    flex-direction: column;
    align-items: center;
}
.DetailDemo_RoundDiv{
    width: 90%;
    height: 60%;
    border-radius: 50% 50% 0 0 ;
}
.DetailDemo_SquareDiv{
    width: 90%;
    height: 60%;
}
.DetailDemo_Img{
    width: 85%;
    top:12%;
}


}
/* @media only screen and (max-width: 600px) {
} */
@media only screen and (max-width: 520px) {
    .DetailDemo_ImgContainer{
        height: 350px;
    }
    .DetailDemo_RoundDiv{
        height: 40%;
        border-radius: 50% 50% 0 0 ;
    }
    .DetailDemo_SquareDiv{
        height: 40%;
    }
 
}