.BlogReadMore_Container{
    padding:  6% 5%;
}
.BlogReadMore_HeaderDiv{
    width: 100%;
    height: 50vh;
}
.BlogReadMore_HeaderImg{
        width: 100%;
        object-fit: cover;
        height: 100%;
}
.BlogReadMore_ContentContainer{
        margin-top: 2% !important;
        display: flex;
        justify-content: space-between;
}
.BlogReadMore_Title{
        color: #0F52BA;
        font-weight: 800 !important;
        font-family: Raleway !important;
        font-size: 2.5rem !important;
}
.BlogReadMore_Date{
        color: #00000090;
        font-weight: 500 !important;
        font-family: Raleway;
        font-size: 1.5rem !important;
}
.BlogReadMore_DespDiv{
   margin-top: 5%; 
   width: 100%;
}
.BlogReadMore_Desp{
        font-size: 1.3rem !important;
        font-weight: 400 !important;
        color: #7A7A7A;
        font-family: Releway;
}
.BlogReadMore_RightGrid{
        margin-top: 8%;
        display: flex;
        justify-content: flex-end;
}
.BlogReadMore_CardsContainer{
    width: 80%;
}
.BlogReadMore_CardsDiv{
    margin-top: 5%;

}

@media only screen and (max-width: 1366px) {
    .BlogReadMore_Container{
        padding:  8% 5%;
    }
    .BlogReadMore_Title{
        font-size: 2rem !important;
}
.BlogReadMore_Date{
        font-size: 1.1rem !important;
}
.BlogReadMore_Desp{
    font-size: 1rem !important;
}
}
@media only screen and (max-width: 900px) {
    .BlogReadMore_Container{
        padding:  10% 5%;
    }
    .BlogReadMore_ContentContainer{
        padding: 0 .5%;
}
.BlogReadMore_Title{
    font-size: 1.8rem !important;
}
.BlogReadMore_RightGrid{
    justify-content: center;
}


}
@media only screen and (max-width: 600px) {
    .BlogReadMore_Container{
        padding:  12% 5%;
    }
    .BlogReadMore_ContentContainer{
        padding: 0 1%;
}
.BlogReadMore_Title{
    font-size: 1.5rem !important;
}
.BlogReadMore_CardsContainer{
    width: 100%;
}


}