.KeyFeatureDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 10%;
}
.KeyFeature_Img{
    width: 15%;
    margin: 3% 0%;
}
.KeyFeature_Title{
    font-weight: 800 !important; 
    margin: 5% 0%;
    font-size: 1rem;
}
.KeyFeature_Desp{
    font-weight: 400 !important;
    font-size: 1rem;
    width: 50%;
    opacity: 80%;
}

@media only screen and (max-width: 1200px) {
    .KeyFeature_Desp{
        width: 70%;
    }
}