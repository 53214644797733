/* NavBar Component Style */
/* .css-1u4elim-MuiPaper-root-MuiAppBar-root {
    box-shadow: 0px 2px 7px -1px rgb(0 0 0 / 20%) !important;
  } */

.MenuDiv {
  height: 100vh;
  background: linear-gradient(180deg, #003b7e 0%, #003b7e 21.35%, #003b7e 100%);
  z-index: 1000;
  margin: 0%;
  width: 100%;
  position: absolute;
  left: 0;
}
.css-13i1luw-MuiPaper-root-MuiAppBar-root {
  padding: 0% !important;
}
.NavToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position:fixed !important; */
  margin: 0% 4%;
  padding: 0 !important;
}
.MenuSectionMain {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
}
.MenuSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0%;
}
.MenuImgDiv {
  width: 35%;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  height: 75vh;
}
.MenuImg {
  width: 100%;
}
.MenuTypoDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
  color: #fff;
  position: relative;
}
.ServiceOptionDiv {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 42%;
  color: #fff;
  border-right: 1px solid;
}
.ServicesFields li {
  list-style: none;
  color: #e5e5e5;
  text-align: right;
  /* font-size: 90%; */
  line-height: 1.7;
  font-weight: 600;
}
.ServicesFields li a {
  color: #e5e5e5;
  text-decoration: none;
}
.MainMenuList li {
  color: #e5e5e5;
  line-height: 2;
  font-weight: 700;
  font-size: 1.5rem;
}
.MainMenuList li a {
  color: #e5e5e5;
  text-decoration: none;
}
.Service_Mobile {
  position: absolute;
  left: -1000%;
  visibility: hidden;
}
.css-1ts5xjn-MuiPaper-root-MuiAppBar-root {
  padding: 0% !important;
}

.text-dot-container {
  display: flex;
  align-items: center;
}
.text-dot-container a {
  color: #e5e5e5;
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 6%;
  white-space: nowrap;
}
.services {
  color: #e5e5e5;
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 6%;
}
.dot {
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}
.class-name {
  border-bottom: 1px solid white;
  width: 25%;
  position: absolute;
  left: 42%;
  top: 17.9px;
}

.MainMenu {
  width: 31%;
  position: absolute;
  left: 67%;
}

.MainMenu > * {
  padding-bottom: 10px;
}

.Menu_languageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Menu_languageDiv {
  width: 30%;
}
.Menu_languageMobMain {
  display: none;
}
.NavLogo_Link {
  width: 50% !important;
}
.Falcon_logo {
  width: 150px !important;
  height: 40px !important;
}

@media only screen and (max-width: 1200px) {
  .Menu_languageDiv {
    width: 40%;
  }
  .NavLogo_Link {
    width: 15% !important;
  }
}
@media only screen and (max-width: 900px) {
  .Falcon_logo {
    height: 30px !important;
  }
  .NavLogo_Link {
    width: 20% !important;
  }
  .MenuSection {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column-reverse;
    width: 100%;
    margin: 2%;
  }
  .MenuImgDiv {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50vh;
  }
  .MenuImg {
    width: 100%;
  }
  .MenuTypoDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*width: 70%;*/
    /* height: 30vh; */
  }
  .MainMenu {
    position: initial;
    width: 33%;
  }
  .ServiceOptionDiv {
    display: flex;
    align-items: center;
    /*width: 50%;*/
    color: #fff;
  }
  .MenuSectionMain {
    width: 100%;
  }
  .class-name {
    top: 26%;
    width: 25%;
  }
  .Service_Mobile {
    width: 100%;
    /* padding-left: 3%; */
    position: absolute;
    left: 0%;
    visibility: visible;
  }
  .NavMenu_box {
    background-color: #003b7e !important;
  }

  .Service_list {
    background-color: #003b7e !important;
  }
  .dropDown_list {
    font-size: 1.5rem;
    padding: 0% 2%;
  }

  .css-1mcnwpj-MuiList-root {
    background-color: #003b7e !important;
    max-width: 100% !important;
    padding: 0% !important;
  }
  .css-10hburv-MuiTypography-root {
    color: #e5e5e5 !important;
    line-height: 1 !important;
    font-weight: 700 !important;
    font-size: 1.5rem !important;
    transition: 0.5s !important;
  }
  .css-10hburv-MuiTypography-root:hover {
    color: #003b7e !important;
  }
  .Nav_links {
    width: 100vw !important;
    padding: 0% 2%;
    color: #fff !important;
  }
  .Nav_links:hover {
    color: #003b7e !important;
  }
  .DropLinks {
    font-size: 10% !important;
    font-weight: 400 !important;
  }
  #Mob_nav {
    transition: 0.5s;
    width: 100vw !important;
  }
  #Mob_nav:hover {
    color: #003b7e !important;
    background-color: white !important;
  }

  .class-name {
    display: none;
  }
  .text-dot-container {
    display: none;
  }

  #Mob_items {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
  }
  .Servive_DropDown {
    font-size: 1.2rem !important;
    color: #fff !important;
    line-height: 1.2 !important;
    width: 100% !important;
  }
  .Servive_DropDown:hover {
    color: #003b7e !important;
  }
  .css-yb0lig {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
  }
  .Menu_languageContainer {
    display: none;
    height: 100%;
    position: relative;
    justify-content: center;
  }
  .Menu_languageDiv {
    position: absolute;
    top: 80%;
    width: 50%;
  }
  .Service_MobileDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
  }
  .Menu_languageMobMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Menu_languageMobDiv {
    width: 80%;
    text-align: center;
    cursor: pointer;
  }
}
@media only screen and (max-width: 600px) {
  /* .Falcon_logo{
    width: 30% !important;
  } */
  .NavLogo_Link {
    width: 25% !important;
  }
  .MenuSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 2% 0%;
  }
  .MenuSectionMain {
    width: 100%;
  }
  .MenuIconMob {
    width: 5% !important;
  }
  .MenuTypoDiv {
    width: 100%;
    height: 1vh;
  }
  .ServiceOptionDiv {
    position: absolute;
    visibility: hidden;
    left: -100%;
  }
  .MenuImgDiv {
    position: absolute;
    left: -100%;
    width: 1%;
  }
  .MainMenuList {
    width: 100%;
  }

  .MainMenuList li {
    width: 100%;
    padding-left: 5%;
    list-style: none;
  }
  .MainMenuList li:hover {
    background-color: #e5e5e5;
    color: #003b7e;
  }
  .MainMenuList li a:hover {
    background-color: #e5e5e5;
    color: #003b7e;
  }
  .MainMenu {
    width: 100%;
    visibility: hidden;
    position: initial;
  }
  .Service_Pc {
    position: absolute;
    left: -100%;
    width: 1%;
  }
}
/* .Service_Mobile {
  color: #003b7e;
} */
/* @media only screen and (max-width: 520px) {
  .Menu_languageDiv{
  width: 70%;
  }
} */

@media only screen and (max-width: 480px) {
  .NavLogo_Link {
    width: 30% !important;
  }
}
