.ClientSectionMain{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    justify-content: center;
    /* height: 100vh; */
    background-color: #F8F8F8;
}
.ClientsDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 60%;
}
 .ClientsGrid{
    /* width: 100% !important; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 5% !important; */
} 
.ClientLogoGrid{
    display: flex;
    justify-content: center;
    margin: 2% 0;
}
.ClientLogo{
 
    width: 70%;
    transform: scale(1);
    filter: grayscale(1);
    transition: .5s;
    cursor: pointer;
}
.ClientLogo:hover{
    transform: scale(1.1);
    filter: grayscale(0);
    
}
@media only screen and (max-width: 600px) {
    .ClientsDiv{
        width: 80%;
    }
    .ClientLogoGrid{
        margin: 5% 0;
    }
}