/* #q {
  width: 0;
  height: 3px;
  background: black;
  transition: 1s;
}
#footerLegalHover:hover + #q {
  width: 100%;
} */
.FooterMain {
  display: flex;
  padding: 5% 0% 1% 0%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  position: relative;
}
.footerBg {
  background-color: #f8f8f8;
}
.FooterListHeading {
  font-size: 1.2rem !important;
}
.FooterMainBg {
  display: flex;
  padding: 5% 5% 1% 5%;
  justify-content: center;
  align-items: center;
}
.FooterDiv {
  display: flex;
  /* justify-content: space-between; */
  width: 90%;
}
.FooterLeft {
  width: 50%;
}
.FooterLogo {
  width: 30%;
  margin-bottom: 3%;
}
.FooterTypo {
  width: 70% !important;
}
.IconsDiv {
  width: 30%;
  display: flex;
  align-items: center;
}
.IconsDiv a {
  margin-right: 1%;
}
.Footer_li {
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 5px 0 !important;
  transition: 0.3s !important;
}
.Footer_li:hover {
  padding: 5px 0 5px 5px !important;
}
.FooterIcons {
  width: 100% !important;
  /* margin-right: 5px; */
  /* margin-right: 20% !important; */
}
.FooterRight {
  display: flex;
  justify-content: space-between;
  width: 45%;
}
.FooterList {
  width: 50%;
  margin-bottom: 25px;
}
.FooterRight ul {
  list-style: none;
  font-weight: 600;
  font-size: 1rem;
}
.FooterRight ul li {
  padding-bottom: 10px;
  font-weight: 800;
}
.FooterRight ul li a {
  color: #003b7e;
  font-weight: 400;
  font-family: Raleway !important;
}
.CopyRightDiv {
  display: flex;
  justify-content: space-between;
  padding: 1% 5%;
  background-color: #f8f8f8;
}
.CopyRightTypo {
  font-size: 1rem !important;
  font-weight: 700 !important;
}
#scrollToTopBtn {
  position: absolute;
  top: 5%;
  right: 5%;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 15%;
}
.LegalMentionDiv {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
}
.LegalMention_H {
  color: black;
  font-weight: 700;
  font-family: Raleway;
}
.LegalMention_H:hover {
  text-decoration-line: underline !important;
}
.Footer_ChangeLanguage {
  color: black;
  font-weight: 700 !important;
  cursor: pointer;
}
.Footer_MobView {
  display: none;
}
/* footer Mob View */
.Footer_MobView_LogoDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 5% 0;
}
.Footer_MobView_LogoLink {
  width: 50%;
  display: flex;
  justify-content: center;
}
.Footer_MobView_ListContainer {
  margin: 3% 0;
}
.Footer_MobView_ListDiv {
  text-align: center;
}
.Footer_MobView_Title {
  color: #282828;
  font-size: 1.4rem;
  margin: 10% 0 !important;
  opacity: 0.5;
  font-weight: 700 !important;
}
.Footer_MobView_List {
  list-style: none;
  color: #003b7e;
  font-weight: 400;
  font-family: Raleway;
}
.Footer_MobView_LegalMentionDiv {
  margin: 10% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Footer_MobView_LegalMentionLink {
  padding: 1% 0;
  margin: 8px 0;
  font-size: 1.1rem;
  color: #282828;
  font-weight: 700;
  text-decoration: underline !important;
}
.Footer_MobView_IconDiv {
  display: flex;
  margin: 5% 0;
}
.Footer_MobView_IconDiv a {
  margin-right: 2%;
}
.Footer_MobView_changeLang {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 3% 0;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .FooterLogo {
    width: 40%;
  }

  .FooterListHeading {
    font-size: 1rem !important;
  }
  .FooterRight {
    width: 45%;
  }

  .LegalMention_H {
    font-size: 0.9rem;
  }
  .Footer_ChangeLanguage {
    font-size: 1rem !important;
    /* width: 70%; */
  }
}

@media only screen and (max-width: 768px) {
  .FooterRight ul {
    font-size: 0.75rem;
  }
  .FooterTypo {
    width: 80% !important;
    font-size: 0.9rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .Footer_WebView {
    display: none !important;
  }
  .Footer_MobView {
    display: flex;
    background: #f8f8f8;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5%;
  }
  .FooterLogoMob {
    width: 100%;
  }
  .FooterMob_Link {
    font-size: 1.2rem;
    width: 100%;
    color: #4879b1 !important;
    font-weight: 400 !important;
  }
  .FooterMob_Li {
    padding: 1.5% 0;
    margin: 8px 0;
  }
  .FooterLogo {
    width: 50%;
  }
  .FooterTypo {
    width: 90% !important;
    font-size: 1rem !important;
  }
  .IconsDiv {
    width: 15%;
  }
  .FooterIcons {
    width: 100% !important;
  }
  .FooterListHeading {
    font-size: 1rem !important;
  }
  .Footer_li {
    font-size: 18px !important;
  }
  .FooterLeft {
    width: 50%;
  }
  .FooterRight {
    width: 40%;
  }
  .FooterRight ul {
    font-size: 1rem;
  }
  .CopyRightTypo {
    width: 50%;
    font-size: 1rem !important;
    font-weight: 700 !important;
  }
  .CopyRightDiv {
    flex-direction: column-reverse;
  }
  .Footer_ChangeLanguage {
    font-size: 1rem !important;
    width: 100%;
    margin-bottom: 0.5% !important;
  }
}
@media only screen and (max-width: 400px) {
  .FooterListHeading {
    font-size: 1rem !important;
  }
  .LegalMentionDiv {
    text-align: center;
  }
  .LegalMention_H {
    font-size: 1rem;
  }
  #scrollToTopBtn {
    right: 9%;
  }
  .CopyRightTypo {
    font-size: 1rem !important;
  }
  .Footer_ChangeLanguage {
    font-size: 1rem !important;
  }
}
