.menu-icon {
  width: 50px;
  height: 50px;
  background: red;
  position: fixed;
  z-index: 2;
  right: 50px;
  top: 10px;
  cursor: pointer;
}
.MenuExpandDiv {
  width: 0;
  transition: 0.5s;
}
.MenuHoverDiv:hover .MenuExpandDiv {
  width: 20px;
}
.menu-icon__line {
  height: 2px;
  width: 50px;
  display: block;
  background-color: #fff;
  margin-bottom: 4px;
  transition: transform 0.2s ease, background-color 0.5s ease;
}
.menu-icon__line-left {
  width: 50px / 2;
}
.menu-icon__line-right {
  width: 50px / 2;
  float: right;
}

/* $menu--items--count: 4; */
/* .menu-icon{ */
.menu-icon__line:hover {
  background-color: #000;
  transform: translateX(0px) rotate(-45deg);
}
.menu-icon__line-left:hover {
  transform: translateX(1px) rotate(45deg);
}
.menu-icon__line-right:hover {
  transform: translateX(-2px) rotate(45deg);
}
.newMenuDiv {
  width: 60%;
  transition-duration: 2s;

  transition-delay: 2s;
}
#menuId {
  margin: 1% 2%;
  color: #e5e5e5;
  font-weight: 400;
  font-size: 2rem;
  list-style: none;
  width: 100%;
  transition: 1s;
  letter-spacing: 0.15rem;
}
#disableId {
  margin: 1% 2%;
  color: #b3b3b3;
  font-weight: 400;
  font-size: 2rem;
  list-style: none;
  width: 100%;
  letter-spacing: 0.15rem;
  transition: 1s;
}
.dropdown_new {
  position: relative;
  width: 100%;
}
#c {
  position: absolute;
  width: 250px;
  height: 0px;
  transition: 0.5s;
  overflow: hidden;
  padding-top: 20px;
}

#c:hover + #b {
  width: 100% !important;
  background: #42c2ff;
}
/* #d:hover + #b {
  width: 100%;
  background: #42c2ff;
}  */

#b {
  height: 2px;
  background: #fff;
  width: 30%;
  margin-top: 1px;
  transition: 0.5s;
}
#d:hover + #b {
  width: 100% !important;
  background: #42c2ff !important;
}
#b_disable {
  height: 2px;
  background: #b3b3b3;
  width: 30%;
  margin-top: 1px;
  transition: 0.5s;
}
#menuId:hover + #b {
  width: 100%;
  background: #42c2ff;
}
#menuId:hover {
  color: #42c2ff;
}
#contactHover:hover + #b {
  width: 100%;
}
.SocialMediaText:hover + #b {
  width: 100%;
}

.MenuDropdownList {
  transform: scaleX(0.9);
  transition: 0.5s;
}
.MenuDropdownList:hover {
  transform: scaleX(1);
}

.MenuDropdownLinks {
  font-size: 1.4rem;
  color: #e5e5e5;
  text-transform: capitalize !important;
}
.MenuDropdownLinks:hover {
  color: #42c2ff;
}
.MenuDropdownLinksDisable {
  font-size: 1.4rem;
  color: #b3b3b3;
}
.spaceDiv {
  width: 0px;
  transition: 1s;
}
.dropdown_new:hover .spaceDiv {
  width: 60px;
}
.dropdown_new:hover #c {
  height: 200px;
  /* overflow: visible; */
}
/* .spaceDiv{
    width: 100px;
    background: red;
    transition-property: width;
    transition-duration: 1s;
    transition-delay: 1s;
} */

/* hamburger */
/* .NavBarBox{
    z-index: 999999999 !important;
    position: fixed !important;
} */
.container {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  right: 5%;
  top: 1.5%;
  z-index: 999999999 !important;
}
.NavbarLanguageDiv {
  width: 100px !important;
  height: 40px !important;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 20px;
  position: fixed !important;
  right: 10% !important;
  top: 1.2% !important;
  z-index: 9999999 !important;
  transition: 0.5s !important;
}
.NavbarLanguageDiv:hover {
  background: #e1e1e1 !important;
  /* background: #42c3ff !important; */
}
.NavbarLanguageMenuDiv {
  width: 100px !important;
  height: 40px !important;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 20px;
  position: fixed !important;
  right: 10% !important;
  top: 1.2% !important;
  z-index: 9999999 !important;
  transition: 0.5s !important;
}
.NavbarLanguageMenuDiv:hover {
  /* background: #e1e1e1 !important; */
  background: #0156b7 !important;
}
/* #langName {
  color: red;
}
#lang:hover + #langName {
  color: #f8f8f8 !important;
} */
.NavbarLanguageFlag {
  width: 100%;
  padding: 3%;
  transition: 0.5s;
}
.NavbarLanguageName {
  transition: 0.5s;
}
.change {
  width: 30px;
  height: 30px;
  position: fixed;
  right: 5%;
  top: 1.5%;
  z-index: 999999999 !important;
  cursor: pointer;
}
.changes {
  width: 30px;
  height: 30px;
  position: fixed;
  right: 5%;
  top: 1.5%;
  z-index: 999999999 !important;
  cursor: pointer;
}
.logoNew {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  left: 5%;
  top: 1%;
  z-index: 999999999999 !important;
  width: 10% !important;
}
.bar1 {
  width: 15px;
  height: 3px;
  background-color: #003b7e;
  margin: 5px 0;
  transition: 0.4s;
}
.bar2 {
  width: 35px;
  height: 3px;
  background-color: #003b7e;
  margin: 5px 0;
  transition: 0.4s;
}
.bar3 {
  width: 35px;
  height: 3px;
  margin: 5px 0;
  transition: 0.4s;
  display: flex;
  justify-content: flex-end;
}
.abc {
  width: 15px;
  background-color: #003b7e;
}
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-1px, 0px);
  transform: rotate(-45deg) translate(-1px, 0px);
  width: 35px;
  background-color: #ffffff;
  position: fixed;
  right: 5%;
  top: 2.5%;
  z-index: 999999999 !important;
  cursor: pointer;
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-1px, 1px);
  transform: rotate(45deg) translate(-1px, 1px);
  position: fixed;
  right: 5%;
  top: 2.5%;
  cursor: pointer;

  z-index: 999999999 !important;
}
.change .abc {
  width: 35px;
  background-color: #ffffff;
}
/* .css-14fbbh9-MuiPaper-root-MuiAppBar-root{
      color:red !important;
  } */
.containers {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  right: 5%;
  top: 1.5%;
  z-index: 999999999 !important;
}
.bars1 {
  width: 15px;
  height: 3px;
  background-color: #ffffff;
  margin: 5px 0;
  transition: 0.4s;
}
.bars2 {
  width: 35px;
  height: 3px;
  background-color: #ffffff;
  margin: 5px 0;
  transition: 0.4s;
}
.bars3 {
  width: 35px;
  height: 3px;
  margin: 5px 0;
  transition: 0.4s;
  display: flex;
  justify-content: flex-end;
}
.abcd {
  width: 15px;
  background-color: #ffffff;
}

.changes .bars1 {
  -webkit-transform: rotate(-45deg) translate(-1px, 0px);
  transform: rotate(-45deg) translate(-1px, 0px);
  width: 35px;
  background-color: #ffffff;
  position: fixed;
  right: 5%;
  top: 2.5%;
  z-index: 999999999 !important;
  cursor: pointer;
}
.changes .bars2 {
  opacity: 0;
}
.changes .bars3 {
  -webkit-transform: rotate(45deg) translate(-1px, 1px);
  transform: rotate(45deg) translate(-1px, 1px);
  position: fixed;
  right: 5%;
  top: 2.5%;
  cursor: pointer;

  z-index: 999999999 !important;
}
.changes .abcd {
  width: 35px;
  background-color: #ffffff;
}

@media only screen and (max-width: 1400px) {
  .newMenuDiv {
    width: 70%;
  }
  .logoNew {
    top: 1.5%;
    width: 12% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .newMenuDiv {
    width: 75%;
  }
  #menuId {
    font-size: 1.7rem;
  }
  .MenuDropdownLinks {
    font-size: 1rem;
  }
  .MenuDropdownLinksDisable {
    font-size: 1rem;
  }
  .logoNew {
    width: 15% !important;
  }
}
@media only screen and (max-width: 900px) {
  .NavbarLanguageFlag {
    padding: 1%;
  }
  .destopView {
    display: none !important;
  }
  .logoNew {
    width: 17% !important;
  }
  .NavbarLanguageDiv {
    width: 90px !important;
    height: 30px !important;
    right: 12% !important;
  }
  .NavbarLanguageMenuDiv {
    width: 90px !important;
    height: 30px !important;
    right: 12% !important;
  }
  .NavbarLanguageName {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 768px) {
  .newMenuDiv {
    width: 85%;
  }
  #menuId {
    font-size: 1.5rem;
  }
  .MenuDropdownLinks {
    font-size: 1rem;
  }
  .logoNew {
    width: 20% !important;
  }
  .NavbarLanguageDiv {
    right: 13% !important;
  }
  .NavbarLanguageMenuDiv {
    right: 13% !important;
  }
  .bar1 {
    width: 10px;
    margin: 4px 0;
  }
  .bar2 {
    width: 25px;
    margin: 4px 0;
  }
  .bar3 {
    width: 25px;
    margin: 4px 0;
  }
  .abc {
    width: 15px;
  }
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-1px, -1px);
    transform: rotate(-45deg) translate(-1px, -1px);
    width: 25px;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-1px, 1px);
    transform: rotate(45deg) translate(-1px, 1px);
  }
  .change .abc {
    width: 25px;
  }

  .bars1 {
    width: 10px;
    margin: 4px 0;
  }
  .bars2 {
    width: 25px;
    margin: 4px 0;
  }
  .bars3 {
    width: 25px;
    margin: 4px 0;
  }
  .abcd {
    width: 15px;
  }

  .changes .bars1 {
    -webkit-transform: rotate(-45deg) translate(-1px, -1px);
    transform: rotate(-45deg) translate(-1px, -1px);
    width: 25px;
  }

  .changes .bars3 {
    -webkit-transform: rotate(45deg) translate(-1px, 1px);
    transform: rotate(45deg) translate(-1px, 1px);
  }
  .changes .abcd {
    width: 35px;
    background-color: #ffffff;
  }
}
@media only screen and (max-width: 600px) {
  .logoNew {
    width: 25% !important;
  }
  .NavbarLanguageDiv {
    right: 15% !important;
  }
  .NavbarLanguageName {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 480px) {
  .logoNew {
    width: 33% !important;
  }
  .NavbarLanguageDiv {
    right: 15% !important;
  }
  .NavbarLanguageMenuDiv {
    right: 15% !important;
  }
}
