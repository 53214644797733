/* Header Component Style */
.HeaderMain{
  display: flex !important;
  justify-content: space-between !important;
  padding: 7.5% 3% 5% 3%;
  align-items: center !important;
  width: 100% !important;
  height: 100%;
}

.HeaderTypo{
  padding: 7% !important ;
}
.HeaderHeading{
  font-size: 2.5rem !important;
  font-weight: 800 !important;
  line-height: 1.2 !important;
  color: #003B7E !important;
  padding-top: 5%!important;
}
.HeaderPara{
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.3 !important;
  margin: 5% 0 !important;
  color: #003B7E;
}
.HeaderPara_Style{
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.3 !important;
  opacity: .7;
  color: #003B7E;

}

.HeaderBtn{
  font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: Raleway !important;
  color: #003B7E !important;
  border: 2px solid #003B7E !important;
  margin: 5% 0 !important;
  border-radius: 8px !important;
  padding: 3% 5% !important;
  transition: .5s !important;
}
.HeaderBtn:hover{
  color: #fff !important;
  background-color: #003B7E !important;
  margin: 4% 0 6% 0 !important;


}
.ProjectDetailBtn{
  color: white !important;
  background-color: #FFA333 !important;
  border: none !important;
  padding: 2% 3% !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  width: 40%;
}
.HeaderImg{
  display: flex;
  justify-content: center;
}
.DetailHeaderImg{
  display: flex;
  justify-content: right;
}
.ChatIconsDiv{
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 95%;
    top: 77%;
    width: 40%;
    z-index: 999;
}
.ChatIconImg{
  width: 8%;
  margin-bottom: 1%;
}
/* Cookie banner */
.CookieBanner_DullScreen{
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.6;
    /* background: #0000001A; */
}
.CookieBanner_Container{
    width: 100%;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    padding: 3% 5%;
    z-index: 10000;

}
.CookieBanner_Div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.CookieBanner_TypoDiv{
  width:40%;
}
.CookieBanner_desp{
    color: #000000;
    font-weight: 500 !important;
    font-family: Montserrat !important;
    font-size: 1.3rem !important;
    line-height: 1 !important;
}
.CookieBanner_Link{
    color: #003B7E;
    font-weight: 500 !important;
    /* font-family: inter !important; */
    text-decoration-line: underline;
    font-size: 1.3rem !important;
}
.CookieBanner_BtnDiv{
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3%;
}
.CookieBanner_AgreeBtn{
    margin-right: 2% !important;
    width: 50%;
    padding: 5.5% !important;
    background: #003B7E !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
}
.CookieBanner_DeclineBtn{
    padding: 5% !important;
    width: 50%;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    border: 3px solid #003B7E !important;
    color: #003B7E !important;
    border-radius: 10px !important;
}
.Social_Icons{
  width: 100%;
  padding-right: 20%;
  filter: grayscale(1);
  transition: .5s;
}
.Social_Icons:hover{
  transform: scale(1.1);
  filter: grayscale(0);
  
}

/* MEDIA QUERIES*/

@media only screen and (max-width: 1366px) {
  .HeaderMain{
    padding: 10% 3%;
  }
  
  .HeaderHeading {
    font-size: 2rem !important;

  }
  .HeaderPara{
    font-size: 1rem !important;
  }
  .CookieBanner_TypoDiv{
    width:50%;
  }
}
/* @media only screen and (max-width: 992px) {
  .HeaderMain{
    height: 80vh;
  }
  .HeaderHeading {
    font-size: 2rem !important;

  }
  .HeaderPara{
    font-size: 1rem !important;
  }
} */
@media only screen and (max-width: 900px) {
  .HeaderTitle{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
  }
  .HeaderMain{
    flex-direction: column-reverse !important;
    padding: 8% 3%;
  }
  .HeaderTypo{
    display: flex;
    flex-direction: column !important;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .HeaderHeading{
    width: 85%;
  }
  .HeaderPara{
    width: 90%;
  margin: 4% 0 !important;
}
.ChatIconsDiv{
  left: 90%;
  top: 75%;
  width: 65%;
}
.BtnLink{
  width: 101% !important;
}
.HeaderBtn{
  padding: 3% 4% !important;
}
.CookieBanner_TypoDiv{
  width:65%;
}
.CookieBanner_desp{
  font-size: 1.2rem !important;
}
.CookieBanner_Link{
  font-size: 1.3rem !important;
}

}
@media only screen and (max-width: 600px) {
    .HeaderMain{
    padding: 15% 3%;
    }
  .HeaderHeading{
    width: 90%;
    font-size: 1.7rem !important;
  }
  .HeaderPara{
    width: 95%;
    font-size: 1rem !important;
  margin: 4% 0 !important;
}
.ChatIconsDiv{
  left: 90%;
  top: 77%;
  width: 90%;
}
.ChatIconImg{
  width: 10%;
}
.CookieBanner_Container{
  padding: 5%;

}

.CookieBanner_TypoDiv{
width:65%;
}
.CookieBanner_desp{
  font-size: .8rem !important;
}
.CookieBanner_Link{
  font-size: 1rem !important;
}
.CookieBanner_BtnDiv{
  width: 25%;
  margin-right: 7%;
}
.CookieBanner_AgreeBtn{
  width: 50%;
  padding: 5.5% !important;
}
.CookieBanner_DeclineBtn{
  padding: 5% !important;
  width: 50%;
}
}
@media only screen and (max-width: 400px) {
  .CookieBanner_BtnDiv{
    flex-direction: column;
    width: 35%;
    margin: 0;
  }
  .CookieBanner_AgreeBtn{
    margin: 2% !important;
    width: 80%;
}
.CookieBanner_DeclineBtn{
    padding: 5% !important;
    width: 80%;
}
}



/* @media only screen and (max-width: 1440px) {
  .btn_main {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 1366px) {
  .btn_main {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 992px) {
  .btn_main {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 600px) {
  .btn_main {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .stepper {
    display: none !important;
  }
}

@media only screen and (max-width: 480px) {
} */
