.BlogArticleMain{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin:5% 2%;
}
.BlogArticleContainer1{
    display: flex !important;
    width: 90% !important;
}
.BlogArticleContainer2{
    display: flex !important;
    width: 90% !important;
}
.BlogTypo{
    margin: 1% !important;
    /* width: 90% !important; */
}
.BlogReadTitle{
   
        color: #0F52BA;
        font-size: 2.5rem !important;
        font-weight: 800 !important;
        width: 75%;
}
.BlogReadDate{
   
        color: #000000 !important;
        font-size: 1.2rem !important;
        font-weight: 500 !important;
        opacity: 60%;
        margin: 2% 0% !important;
}
.BlogReadPara{
   
        font-size: 1rem !important;
        font-weight: 500 !important;
        width: 90%;
}
.BlogReadBtn{
   
     color: #42C2FF !important;
      margin-top: 2% !important; 
      font-weight: 500 !important;
      font-size: 1rem !important;

}
.BlogReadImg{
    width: 90%;
    border-radius: 15px;
}
@media only screen and (max-width: 1400px) {
    .BlogReadTitle{
   
        font-size: 1.8rem !important;
}
.BlogReadDate{
   
        font-size: 1rem !important;
}
.BlogReadPara{
   
        font-size: 1rem !important;
}


}
@media only screen and (max-width: 1200px) {
    .BlogReadTitle{
   
        font-size: 1.5rem !important;
}
.BlogReadPara{
   
        font-size: 1rem !important;
}
.BlogReadBtn{
   
    font-size: 1.1rem !important;

}




}
@media only screen and (max-width: 900px) {

    .BlogArticleContainer1{
        flex-direction: column-reverse !important;
        align-items: center;
    }
    .BlogArticleContainer2{
        justify-content: center;
    }
    .BlogReadImg{
        width: 100%;
    }
    .BlogReadBtn{
   
        font-size: 1rem !important;
    
    }


}

@media only screen and (max-width: 600px) {
    .BlogTypo{
        text-align: center;
    }
    .BlogReadTitle{
   
      
        width: 100%;
}
    .BlogReadImg{
        width: 97%;
    }
    .BlogReadPara{
        width: 100%;

    }
}