.ReviewSectionMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ReviewCardSlider{
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 0;
    height: 50vh;
    width: 100%;
    align-items: center;
    background-image: url("/public/images/Background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ReviewSliderContainer{
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 2% 0;
    height: 50vh;
    width:90%;
    align-items: center;
}

.fade-left{
    position: absolute;
    left:10%;
    top:0;
    height: 100%;
    width: 20%;
    z-index: 99;
    background-image: linear-gradient(to right, rgba(255,255,255, 1) ,rgba(255,255,255, 0.8) , rgba(255,255,255, 0));
}

.fade-right{
    position: absolute;
    right:10%;
    top:0;
    height: 100%;
    width: 20%;
    z-index: 99;
    background-image: linear-gradient(to left, rgba(255,255,255, 1) ,rgba(255,255,255, 0.8) , rgba(255,255,255, 0));
}



.SliderDiv2{
    width: 100% !important;
    /* opacity: 50%; */
}
/* .SliderDiv:focus{
    opacity: 100%;
} */
.ReviewCardMain{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
    width: 100%;
    height: 100%;
}
.ReviewCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5% 1%;
    border: 1px solid #FCFCFC;
    border-radius: 5%;
    background: #FCFCFC;
    box-shadow: 0px 7px 20px rgba(124, 167, 250, 0.4);
    height: 47%;
    width: 100%;
    transition: .5s ease-in-out;
    transform: scale(.9);
    filter:grayscale(.5);
    opacity: 1;
}
.Selection::selection { 
    background: transparent; 
} 
.ReviewSection_Carousel{
    overflow-x: scroll;

}
.react-stacked-center-carousel {
    height: 350px !important;
}
/* ::-moz-selection { background: transparent; } */
.ReviewCard_Container{
        user-select: none;
        max-width: 400px;
        min-width: 200px;
        height: 550px;
        border-radius: 15;
}
.CarouselLeftArrow {
    position: absolute !important;
    top: 40% !important;
    left: 5% !important;
    z-index: 1000 !important;
    color: #003B7E;
    background: #F8F8F8 !important;
    box-shadow: 0px 3px 12px rgba(140, 138, 138, 0.15);
    transition: .5s;
}
.CarouselLeftArrow:hover{
    background: #003B7E !important;
    color: #F8F8F8 !important;
}
.CarouselRightArrow{
        position: absolute !important;
        top: 40% !important;
        right: 5% !important;
        color: #003B7E;
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 12px rgba(140, 138, 138, 0.15);
        transition: .5s;
}
.CarouselRightArrow:hover{
    background: #003B7E !important;
    color: #F8F8F8 !important;
}
@media only screen and (max-width: 1200px) {
    .ReviewCard{
        margin-top: 40px;
    }

}
  
@media only screen and (max-width: 1100px) {
    .fade-left{
        width: 0;
    }
    .fade-right{
        width: 0;
    }
    .ReviewSliderContainer{
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .ReviewCard{
        margin-top: 60px;
    }
}
/* @media only screen and (max-width: 480px) {

    .ReviewCard_Container{
        min-width: 300px;
        margin-left: -22%;
}
}
@media only screen and (max-width: 400px) {
    .ReviewCard_Container{
        margin-left: -30%;
}
}
@media only screen and (max-width: 340px) {
    .ReviewCard_Container{
        min-width: 250px;
}
.ReviewCardSlider{
    margin: 10% 0;
}
} */

  