.DetailService_Container {
    position: relative;
    width: 100%;
    padding: 5%;
}

.DetailService_TitleDiv {
    display: flex;
    align-items: center;
}

.DetailService_TitleDivider {
    width: 7%;
    height: 2px;
    margin-right: 1% !important;
}

.DetailService_Title {
    font-weight: 700 !important;
    font-size: 1.5rem !important;
}
.DetailService_ImageDiv{
   width: 100%; 
   display: flex; 
   justify-content: center;

}
.DetailService_BothImage{
    width: 80%; 
    margin: 5% 2%; 
    object-fit: cover;
}
.DetailService_WebAppImage{
    width: 60%; 
    margin: 5% 2%; 
    object-fit: cover;
}
.DetailService_CircleContainer {
    display: flex;
    justify-content: center;
    margin: 10% 0;
}

.DetailService_UpperBorder {
    width: 170px;
    height: 82px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border: 10px solid;
    border-bottom: 0 !important;
    padding: .2%;
    position: relative;
    right: -0.1%;

}

.DetailService_CircleOdd {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 145px;
    background-color: #FFA333;
    border-radius: 50%;
    padding: 2%;
    position: absolute;
    top: 2;
}

.DetailService_LowerBorder {
    width: 170px;
    height: 82px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border: 10px solid;
    border-top: 0 !important;
    padding: .2%;
    position: relative;
    top: 86px;
    left: -0.4%;
}

.DetailService_CircleEven {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 145px;
    height: 145px;
    background-color: #FFA333;
    border-radius: 50%;
    padding: 2%;
    position: absolute;
    bottom: 2px;
}

.DetailService_TypoDiv1 {
    width: 95%;
    text-align: center;
    position: absolute;
    top: 190px;
}

.DetailService_TypoDiv2 {
    width: 95%;
    text-align: center;
    position: absolute;
    top: 115px;
}

.DetailService_Heading {
    font-size: 1rem !important;
    font-weight: 700 !important;
}

.IconImage {
    width: 50%;
    padding:5%;
}
/* Background Logo  */
.DetailService_BGLogo_Div{
        position: absolute;
        width: 100%;
        height: 100%;
}
.DetailService_BGLogo_Img1{
        position: absolute;
        top: 0;
        right: 10%;
        width: 20%;
}
.DetailService_BGLogo_Img2{
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 17%;
}
.DetailService_BGLogo_Img3{
        position: absolute;
        bottom: 15%;
        right: 15%;
        width: 15%;
}
.DetailService_FitLogo_Div{
    position: absolute;
    width: 100%;
    height: 100%;
}
.DetailService_FitLogo_Img1{
        position: absolute;
        top: 0;
        right: 10%;
        width: 10%;
}
.DetailService_FitLogo_Img2{
        position: absolute;
        bottom: 5%;
        left: 0;
        width: 8%;
}
.DetailService_FitLogo_Img3{
        position: absolute;
        bottom: 15%;
        right: 20%;
        width: 7%;
}

@media only screen and (max-width: 1500px) {
    .DetailService_UpperBorder {
        width: 120px;
        height: 60px;
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
        border: 8px solid;

    }

    .DetailService_CircleOdd {
        width: 100px;
        height: 100px;
    }

    .DetailService_LowerBorder {
        width: 120px;
        height: 60px;
        border-bottom-left-radius: 75px;
        border-bottom-right-radius: 75px;
        border: 8px solid;
        top: 62px;
    }

    .DetailService_CircleEven {
        width: 100px;
        height: 100px;
    }

    .DetailService_TypoDiv1 {
        top: 135px;
    }

    .DetailService_TypoDiv2 {
        top: 80px;
    }

    .DetailService_Heading {
        font-size: 1rem !important;
    }

}

@media only screen and (max-width: 1024px) {
    .DetailService_UpperBorder {
        width: 100px;
        height: 45px;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border: 5px solid;

    }

    .DetailService_CircleOdd {
        width: 85px;
        height: 85px;
    }

    .DetailService_LowerBorder {
        width: 100px;
        height: 45px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        border: 5px solid;
        top: 47px;
    }

    .DetailService_CircleEven {
        width: 85px;
        height: 85px;
    }

    .DetailService_TypoDiv1 {
        top: 120px;
    }

    .DetailService_TypoDiv2 {
        top: 80px;
    }

    .DetailService_Heading {
        font-size: 1rem !important;
    }

}

@media only screen and (max-width: 900px) {
    .DetailService_UpperBorder {
        width: 78px;
        height: 35px;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        border: 5px solid;

    }

    .DetailService_CircleOdd {
        width: 65px;
        height: 65px;
    }

    .DetailService_LowerBorder {
        width: 78px;
        height: 35px;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        border: 5px solid;
        top: 38px;
    }

    .DetailService_CircleEven {
        width: 65px;
        height: 65px;
    }

    .DetailService_CircleContainer {
        margin: 15% 0;
    }

    .DetailService_Heading {
        font-size: 1rem !important;
    }

    .DetailService_TypoDiv1 {
        top: 113px;
    }


}

@media only screen and (max-width: 600px) {

    .DetailService_UpperBorder {
        width: 60px;
        height: 25px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border: 4px solid;

    }

    .DetailService_CircleOdd {
        width: 50px;
        height: 50px;
    }

    .DetailService_LowerBorder {
        width: 60px;
        height: 25px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 4px solid;
        top: 28px;
    }

    .DetailService_CircleEven {
        width: 50px;
        height: 50px;
    }

    .DetailService_CircleContainer {
        margin: 15% 0 25% 0;
    }

    .DetailService_Heading {
        font-size: 1rem !important;
    }

    .DetailService_TypoDiv1 {
        top: 100px;
    }

    .DetailService_TypoDiv2 {
        top: 75px;
    }
    .DetailService_BothImage{
        width: 90%; 
    }
    .DetailService_WebAppImage{
        width: 80%; 
    }
}
@media only screen and (max-width: 400px) {
    .DetailService_BothImage{
        width: 100%; 
    }
    .DetailService_WebAppImage{
        width: 100%; 
    }

    .DetailService_UpperBorder {
        width: 40px;
        height: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border: 4px solid;

    }

    .DetailService_CircleOdd {
        width: 30px;
        height: 30px;
    }

    .DetailService_LowerBorder {
        width: 40px;
        height: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border: 4px solid;
        top: 18px;
    }

    .DetailService_CircleEven {
        width: 30px;
        height: 30px;
    }

    .DetailService_CircleContainer {
        margin: 15% 0 25% 0;
    }

    .DetailService_Heading {
        font-size: 1rem !important;
    }

    .DetailService_TypoDiv1 {
        top: 100px;
    }

    .DetailService_TypoDiv2 {
        top: 75px;
    }
}