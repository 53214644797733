.BlogHeaderMain{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 60vh;
    
}
::placeholder{
    color: rgb(211, 204, 204);
}
.BlogHeader_Btn{
        background-color: #0F52BA;
        border: none;
        width: 40%;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 35px;
        padding: 1%;
        color: white;
        cursor: pointer;
}
.BlogHeaderInput{

    width: 40%;
    border: 1px solid rgb(235, 233, 233);
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
    border-radius: 35px;
    display: flex;
    height: 12%;
    margin-top: 1%;
}
@media only screen and (max-width: 900px) {
.BlogHeaderInput{
    width: 60% !important;
    margin-top: 3% !important;

}
.BlogHeaderTypo{
    width: 50% !important;
}

}

@media only screen and (max-width: 600px) {
    .BlogHeaderInput{
        width: 80% !important;
    }
    .BlogHeaderTypo{
        width: 70% !important;
    }
    .BlogHeader_Btn{
        padding: 3% 5%;

}
}
@media only screen and (max-width: 400px) {
    .BlogHeaderTypo{
        width: 90% !important;
    }
}